import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

const useStaff = () => {
  const toast = useToast();

  const createStaff = async (staff) => {
    try {
      await store.dispatch('staff/update', staff);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the staff',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const deleteStaff = async (staff) => {
    try {
      await store.dispatch('staff/remove', staff.id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the staff',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const editStaff = async (staff) => {
    try {
      await store.dispatch('staff/create', staff);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the staff',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getListStaff = async (params = {}) => {
    try {
      return await store.dispatch('staff/getList', params);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching staff list',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    createStaff,
    deleteStaff,
    editStaff,
    getListStaff,
  };
};

export default useStaff;
