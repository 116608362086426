<template>
  <div>

    <staff-form
      v-if="isAddNewStaffSidebarActive"
      :is-add-new-staff-sidebar-active.sync="isAddNewStaffSidebarActive"
      :staff="staff"
      @form-submitted="formSubmitted"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Press ENTER to search"
                @keyup.enter="refStaffListTable.refresh();"
              />

              <b-button
                variant="primary"
                @click="openSidebar"
              >
                <span class="text-nowrap">Add Staff</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refStaffListTable"
        class="position-relative"
        :items="getStaffList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="orderDesc"
      >
        <!-- Column: First Name -->
        <template #cell(firstname)="data">
          <p class="font-weight-bold d-block text-nowrap">
            {{ data.item.firstname }}
          </p>
        </template>

        <!-- Column: Last Name -->
        <template #cell(lastname)="data">
          <p class=" d-block text-nowrap">
            {{ data.item.lastname }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editStaff(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pageNumber"
              :total-rows="totalStaff"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api';
import { BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useStaffList from '@/modules/staff/composables/useStaffList';
import StaffForm from '@/modules/staff/components/StaffForm.vue';
import Swal from 'sweetalert2';
import useStaff from '../composables/useStaff';

export default {
  setup() {
    const { deleteStaff } = useStaff();
    const {
      pageNumber,
      dataMeta,
      getStaffList,
      orderDesc,
      pageSize,
      perPageOptions,
      refStaffListTable,
      searchQuery,
      orderBy,
      tableColumns,
      totalStaff,
    } = useStaffList();

    const emptyStaff = { id: null, firstname: null, lastname: null };
    const isAddNewStaffSidebarActive = ref(false);

    const staff = ref({ ...emptyStaff });

    const removeStaff = async (staff) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteStaff(staff);

      refStaffListTable.value.refresh();
    };

    const editStaff = (staffSelected) => {
      staff.value = { ...staffSelected };
      isAddNewStaffSidebarActive.value = true;
    };

    const formSubmitted = () => {
      isAddNewStaffSidebarActive.value = false;
      staff.value = { ...emptyStaff };
      refStaffListTable.value.refresh();
    };

    const openSidebar = () => {
      staff.value = { ...emptyStaff };
      isAddNewStaffSidebarActive.value = true;
    };

    return {
      // Reactive
      isAddNewStaffSidebarActive,
      staff,

      //Method
      removeStaff,
      editStaff,
      formSubmitted,
      openSidebar,

      // UseStaffList
      pageNumber,
      dataMeta,
      getStaffList,
      orderDesc,
      pageSize,
      perPageOptions,
      refStaffListTable,
      searchQuery,
      orderBy,
      tableColumns,
      totalStaff,
    };
  },
  components: {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    StaffForm,
    vSelect,
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
