<template>
  <b-sidebar
    id="add-new-staff-sidebar"
    :visible="isAddNewStaffSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-staff-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ staffData.id ? 'Update' : 'Add New' }} Staff</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="newStaffForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validationForm()"
        >
          <b-form-group
            label="First Name"
            label-for="first-name"
          >
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="required"
            >
              <b-form-input
                id="first-name"
                v-model="staffData.firstname"
                :state="errors.length > 0 ? false : null"
                name="first-name"
                placeholder="First Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Last Name"
            label-for="last-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                id="last-name"
                v-model="staffData.lastname"
                :state="errors.length > 0 ? false : null"
                name="last-name"
                placeholder="Last Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ staffData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import useStaff from '@/modules/staff/composables/useStaff';

export default {
  props: {
    isAddNewStaffSidebarActive: {
      type: Boolean,
      required: true,
    },
    staff: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const { createStaff, editStaff } = useStaff();

    const newStaffForm = ref(null);
    const staffData = ref(props.staff);
    const isLoading = ref(false);

    const resetForm = () => {
      newStaffForm.value.reset();
    };

    const validationForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await newStaffForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      staffData.value.id
        ? await createStaff(staffData.value)
        : await editStaff(staffData.value);

      ctx.emit('form-submitted');

      isLoading.value = false;
    };

    return {
      // Reactive
      newStaffForm,
      staffData,
      isLoading,

      // Method
      resetForm,
      validationForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationObserver,
    ValidationProvider,
    BSpinner
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-staff-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
